
@media screen and (min-width: 2560px) and (min-height: 1440px) {
    .customModal {
      width: fit-content;
      max-width: 30vw;
    }
  }

  @media screen and (min-width: 1440px) and (min-height: 2560px) {
    .customModal {
      width: fit-content;
      max-width: 40vw;
    }
  }